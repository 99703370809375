<template>
  <div class="chart">
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="筛选条件" class="filter">
          <van-cell title="选择设备查询" is-link @click="filter.dev.show = true"/>
          <van-cell title="请选择开始时间" :value="filter.time.startVal.toString()" is-link @click="filter.time.startShow = true"/>
          <van-cell title="请选择结束时间" :value="filter.time.endVal" is-link @click="filter.time.endShow = true"/>
          <van-row class="text-center">
              <van-col span="12"><van-button type="primary" size="small" @click="resetData">重置</van-button></van-col>
              <van-col span="12"> <van-button type="primary" size="small" @click="filterData">筛选</van-button></van-col>
          </van-row>
      </van-collapse-item>
    </van-collapse>
    <div class="chart-content">
        <div v-for="(item,index) in devListChartData" :key="index">
            <div class="title">护栏传感器S N-{{item.contactId}}</div>
            <div class="time-0">{{ `${filter.time.startVal} - ${filter.time.endVal}`}}</div>
            <!-- <div class="title"><i class="fa fa-area-chart"></i>护栏传感器S N-{{item.contactId}}</div> -->
            <div v-if="loading">
                <van-loading type="spinner" vertical style="margin:4rem 0">加载中</van-loading>
            </div>
            <div v-else class="glo-relative">
                <!-- <div :id=""></div> -->
                <canvas :id="'chart'+index" class="chart-00"></canvas>
                <div class="x-label">(ms)</div>
                <div class="y-label">
                  <div>撞击强度</div>
                </div>
            </div>
        </div>
    </div>
    <!-- 筛选设备 -->
    <van-popup v-model="filter.dev.show" round position="bottom"  :style="{ height: '45%' }">
        <div class="filter-dev-area">
            <van-checkbox-group v-model="filter.dev.value" @change="changeCheckout">
                <van-cell-group>
                    <van-cell
                    v-for="(item, index) in filter.dev.columns"
                    clickable
                    :key="item.devCode"
                    :title="item.devName"
                    @click="toggle(index)"
                    >
                    <template #right-icon>
                        <van-checkbox :name="item.devCode" ref="checkboxes" checked-color="#34a53c" />
                    </template>
                    </van-cell>
                </van-cell-group>
            </van-checkbox-group>
        </div>
    </van-popup>
    <!-- 筛选时间段 -->
    <van-popup v-model="filter.time.startShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.time.start"
        type="datetime"
        title="选择开始时间"
        @confirm="confromStartTime"
        @cancel="filter.time.startShow = false"
      />
    </van-popup>
    <van-popup v-model="filter.time.endShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.time.end"
        type="datetime"
        title="选择结束时间"
        @confirm="confromEndTime"
        @cancel="filter.time.endShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";
const F2 = require("@antv/f2");
export default {
  data() {
    return {
      activeName: '',
      loading:true,
      filter:{
        dev:{
          value:[],
          show:false,
          columns:[]
        },
        time:{
          start:new Date(),
          end:new Date(),
          startVal:'',
          endVal:'',
          startShow:false,
          endShow:false,
        }
      },
      devListChartData:[],
      chart:[]
    }
  },
  methods:{
    // 初始化日期
    initDate(val){
        // 前后 1 秒
        const time = moment(val).valueOf();
        this.filter.time.startVal = moment(time - 1*1000).format('YYYY-MM-DD HH:mm:ss');
        this.filter.time.endVal = moment(time + 1*1000).format('YYYY-MM-DD HH:mm:ss');
        this.filter.time.start = new Date(time - 1*1000);
        this.filter.time.end = new Date(time + 1*1000);
    },
    // 确认开始时间
    confromStartTime(value){
      this.filter.time.startVal = moment(value).format("YYYY-MM-DD HH:mm:ss");
      this.filter.time.startShow = false;
    },
    // 确认结束时间
    confromEndTime(value){
      this.filter.time.endVal = moment(value).format("YYYY-MM-DD HH:mm:ss");
      this.filter.time.endShow = false;
    },
    changeCheckout(val){
      if(val.length>4){
        this.filter.dev.value.pop();
        return this.$toast('最多不能超过4个')
      }
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    // 重置
    resetData(){
      this.filter.dev.value = [];
      this.initDate(this.$route.query.time);
    },
    // 筛选
    filterData(){
      if(this.filter.dev.value.length == 0){
        return this.$toast('请勾选设备，设备不能为空')
      }
      this.$nextTick(()=>{
        if(this.chart.length){
          this.chart.forEach( e => {
            e.destroy()
          })
        }
      })
      this.loading = true;
      this.activeName = '';
      this.getChartInfo();
    },
    axiosChart(devCode){
        return new Promise((resolve) => {
            this.$api.WGS.getDateCharts({
                devCode:devCode,
                beginTime:this.filter.time.startVal ? this.filter.time.startVal : void 0,
                endTime:this.filter.time.endVal ? this.filter.time.endVal : void 0,
            }).then( data =>{
                resolve(data)
            })
        })
    },
    // 获取数据
    async getChartInfo(){
        this.devListChartData = [];
        for(let i =0 ;i<this.filter.dev.value.length;i++){
            const item = await this.axiosChart(this.filter.dev.value[i]);
            let itemDevice = [];
            for (let j= 0; j < item.length;j++){
                const handleValue = item[j].force2.split(',').map(v => Number(v));
                const data = handleValue.map( (v,z) => ({x:z*40,y:v,type:item[j].uploadTime}));
                itemDevice = [...itemDevice,...data]
            }
            this.devListChartData.push({
                devCode:this.filter.dev.value[i],
                contactId:this.filter.dev.columns.find(v => v.devCode === this.filter.dev.value[i])?.contactId,
                data:itemDevice
            })
        }
        this.showChart();
    },
    // 绘制数据
    showChart(){
      this.loading = false;
      this.$nextTick(()=>{
          for(let i=0;i<this.devListChartData.length;i++){
            const data = this.devListChartData[i].data;
            this.chart[i] = new F2.Chart({
                id: "chart"+[i],
                pixelRatio: window.devicePixelRatio,
                padding: [ 'auto', 40, 'auto', 60 ]
            });
            this.chart[i].source(data);
            this.chart[i].scale("x", {});

            this.chart[i].scale("y", {
                tickCount: 6,
                min:0,
            });
            // this.chart[i].axis("x", {
            // label: function label(text, index, total) {
            //     const textCfg = {};
            //     if (index === 0) {
            //     textCfg.textAlign = "left";
            //     } else if (index === total - 1) {
            //     textCfg.textAlign = "right";
            //     }
            //     return textCfg;
            // },
            // });
            this.chart[i].tooltip({
                custom: true, // 自定义 tooltip 内容框
                onChange: (obj) => {
                    const legend = this.chart[i].get('legendController').legends.top[0];
                    const tooltipItems = obj.items;
                    const legendItems = legend.items;
                    const map = {};
                    legendItems.forEach((item)=>{
                        map[item.name] = item;
                    });
                    tooltipItems.forEach((item)=> {
                        const name = item.name;
                        const value = item.value;
                        map[name].value = value;
                    });
                    legend.setItems(Object.values(map));
                },
                onHide:() => {
                    const legend = this.chart[i].get('legendController').legends.top[0];
                    legend.setItems(this.chart[i].getLegendItems().country);
                }
            });
            this.chart[i]
            .line({
                connectNulls: true, // 将空数据连接
            })
            .shape("smooth")
            .position("x*y").color('type')
            this.chart[i].render();
          }
      })
    },
    // 获取设备信息
    getDevInfo(){
      this.$api.WGS.getAllDev({
        projectCode:this.projectCode
      }).then( d => {
        this.filter.dev.columns = d;
        // 默认选择当前设备
        this.filter.dev.value = [ this.devCode ]
        this.getChartInfo();
      })
    }
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.devCode = this.$route.query.devCode;
      this.initDate(this.$route.query.time);
      this.getDevInfo();
  }
}
</script>
<style lang="scss" scoped>
.filter{text-align: left;}
.text-center{
  text-align: center;
  button{
    padding: 0 20px;
    margin-top: 5%;
  }
}
.filter-dev-area{
    text-align: left;
    // padding: 20px;
    margin: 20px 30px;
    overflow-y: scroll;
}
.chart-00{width: 100%;height: 18rem;}
.title{
  text-align: left;font-size: .95rem;line-height:2;
  text-align: center;
  font-weight: bolder;
  .fa{color: #34a53c;font-size: 1.2rem;padding-right: 2%;}
}
.time-0{
  font-size: .7rem;color:#ccc;
}
.x-label{
  font-size: .7rem;
  color: #676767;
  position: absolute;
  bottom: 6.5%;
  right: 1%;
}
.y-label{
    font-size: 0.7rem;
    color: #676767;
    position: absolute;
    left: 0%;
    bottom: 18%;
    transform: rotate(-90deg);
}
.chart-content{
    padding-top: 10px;
    padding-bottom: 22%;
}
</style>